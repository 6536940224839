//导入api.js
import service from '@/axios'
// import qs from 'qs'

export function getUserAuth(data) {
  return service({
    url: '/adminRole/auth',
    method: 'post',
    data: data
  })
}

//查询量体任务 {status,flowNumOrName}
export function searchMeasureTask(data) {
  return service({
    url:'/measureBodyMeasure/queryMeasureTask',
    method: 'post',
    data,
    headers: {
      'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8'
    }
  })
}


//花名册复制量体数据 {measurerList,sourceId}
export function copyRousterMeasureData(data) {
  return service({
    url:'/measureBodyMeasure/copyMeasureData',
    method: 'post',
    data,
    headers: {
      'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8'
    }
  })
}

//花名册下载{taskId}
export function exportRoster(data) {
  return service({
    url:'/measureMeasurePersons/exportRoster',
    method: 'post',
    data,
    responseType:'blob'
  })
}



//查询量体数据上传接口状态
//@return null:未上传 {status:[-1:上传失败,0:上传中,1:上传成功]}
export function queryTaskUploadStatus(data) {
  return service({
    url:'/measureBodyMeasure/queryTaskUploadStatus',
    method: 'post',
    data,
    headers: {
      'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8'
    }
  })
}

//批量上传量体任务量体数据
export function batchAddBodyMeasure(data) {
  return service({
    url: '/measureBodyMeasure/batchAddBodyMeasure',
    method:'post',
    data
  })
}

// 登录
export function getLogin(data) {
  return service({
    url: '/login',
    method:'post',
    data
  })
}
//获取用户信息
export function queryLoginUser() {
  return service({
    url: '/adminUser/queryLoginUser',
    method:'post'
  })
}


// 修改密码
export function getEditPassword(data) {
  return service({
    url:'/adminUser/updatePassword',
    method: 'post',
    data,
    headers: {
      'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8'
    }
  })
}


// 获取当前量体师下得量体任务
export function getCurrentMeasureTasks(data) {
  return service({
    // url: '/tailor/api/tasks',
    url:'/measureBodyMeasure/queryByMeasurerId',
    method: 'post',
    data,
    headers: {
      'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8'
    }
  })
}


// 查询部门列表
export function getDepartments() {
  return service({
    url: '/admin/tailors/departments',
    method: 'get'
  })
}
// 查询岗位列表
export function getPositions(data) {
  return service({
    url: '/tailor/api/positions',
    method: 'get',
    data
  })
}
// // 查询岗位列表
// export function getPositions() {
//   return service({
//     url: '/admin/tailors/positions',
//     method: 'get'
//   })
// }

// 修改量体任务状态
export function getEditStatus(data) {
  return service({
    url: '/admin/measure-tasks/edit-status',
    method: 'put',
    data
  })
}

// 确认任务
export function getConfirmTask(data) {
  return service({
    url: '/measureBodyMeasure/confirmTask',
    method: 'post',
    data,
    headers: {
      'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8'
    }
  })
}

//推进任务 
export function advanceTask(data) {
  return service({
    url: '/measureTask/advance',
    method: 'post',
    data
  })
}


// 提交任务
export function getSubmitTask(data) {
  return service({
    url: '/tailor/api/submit-task',
    method: 'put',
    data
  })
}

// 量体师签到
export function getSignIn(data) {
  return service({
    url: '/tailor/api/signin',
    method: 'post',
    data
  })
}


// 查询任务下的品类信息
export function getCategoryDetail(data) {
  return service({
    // url: '/tailor/api/categorys',
    url:'/measureTask/queryTaskCommodityByTaskId',
    method: 'post',
    data,
    headers: {
      'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8'
    }
  })
}
// 查询任务下的信息概况
export function getInfoDetail(data) {
  return service({
    // url: '/tailor/api/categorys',
    url:'/measureTask/information',
    method: 'post',
    data,
    headers: {
      'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8'
    }
  })
}

// 添加量体人品类
export function getPersonCategory(data) {
  return service({
    url: '/tailor/api/person-category',
    method: 'post',
    data
  })
}

// 查询品类下着装风格
export function getDressStyle(data) {
  return service({
    url: '/measureDressStyle/queryDressStyleByCategoryId',
    method: 'post',
    data,
    headers: {
      'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8'
    }
  })
}

// 查询花名册的所有品类
export function getPersonsCategory(params) {
  return service({
    url: '/admin/measure-tasks/persons-category',
    method: 'get',
    params
  })
}
//花名册详情 动态表头
export function queryPersonCommodityFieldByTaskId(params) {
  return service({
    url: '/measureTask/queryPersonCommodityFieldByTaskId',
    method: 'post',
    params
  })
}

//配置表 动态表头
export function queryCategoryConfigFieldByTaskId(params) {
  return service({
    url: '/measureTask/queryCategoryConfigFieldByTaskId',
    method: 'post',
    params,
  });
}
//配置表数据
export function queryPersonsCategoryConfig(data) {
  return service({
    url: '/measureTask/queryPersonsCategoryConfig',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  });
}

// 查询某个量体人呢的所有品类
export function getMeasurePersonCategory(params) {
  return service({
    url: '/admin/measure-tasks/measure-person-category',
    method: 'get',
    params
  })
}

// 查询特体数据
export function getSpecialBody(data) {
  return service({
    url: '/measureSpecialBody/querySpecialBodyByCategoryId',
    method: 'post',
    data,
    headers:{
      'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8'
    }
  })
}

// 查询量体任务花名册
export function getMeasurePersons(data) {
  return service({
    // url: '/tailor/api/persons',
    url:'/measureTask/queryPersonCommodityByTaskId',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

//去量体花名册
export function getMeasureRoster(data,cancelToken=null) {
  return service({
    url:'/measureMeasurePersons/queryPageList',
    method: 'post',
    data,cancelToken
  })
}

//修改花名册备注 {measurePersonId,remark}
export function saveRemark(data) {
  return service({
    url:'/measureBodyMeasure/saveRemark',
    method: 'post',
    data,
    headers:{
      'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8'
    }
  })
}

//查询任务-品类-货号对应的spec
export function getSpecInfo(data) {
  return service({
    url: '/measureSizeTable/querySizeTabelById',
    method: 'post',
    data,
    headers:{
      'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8'
    }
  })
}

// 查询量补量任务花名册
export function getMeasurePersonsNew(params) {
  return service({
    url: '/tailor/api/persons-for-aftersale-amend-person',
    method: 'get',
    params
  })
}


// 查询任务中的片区
export function getTaskPlaces(data) {
  return service({
    url:'/measureMeasurePersons/queryPlace',
    method: 'post',
    data,
    headers: {
      'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8'
    }
  })
}
//查询任务中的部门
export function getTaskDepts(data) {
  return service({
    url:'/measureMeasurePersons/queryDept',
    method: 'post',
    data,
    headers: {
      'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8'
    }
  })
}
//查询任务中属性选项
export function getTaskAttrOptions(taskId,field) {
  return service({
    url:`measureMeasurePersons/queryMeasurePersonsField/${taskId}/${field}`,
    method: 'post',
    // data,
    // headers: {
    //   'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8'
    // }
  })
}


// 查询各个任务状态的数量
export function getStatusSum(params) {
  return service({
    url: '/tailor/api/status-sum',
    method: 'get',
    params
  })
}

// 添加/暂存 量体人
export function getPersonsAdd(data) {
  return service({
    url: '/tailor/api/persons',
    method: 'post',
    data
  })
}

// 获取暂存名单
export function getTemporaryPersons(params) {
  return service({
    url: '/tailor/api/temporary-persons',
    method: 'get',
    params
  })
}

// 量体数据页面
export function getBodyInfo(params) {
  return service({
    url: '/tailor/api/body-info',
    method: 'get',
    params
  })
}

//量体页面净体表单排序 
export function addOrUpdatePositionSort(data) {
  return service({
    url: '/measurePosition/addOrUpdatePositionSort',
    method: 'post',
    data
  })
}


// 量体数据页面
export function getBodyInfoSelect(data) {
  return service({
    url: '/measureBodyMeasure/queryInitializeMeasurePersionData',
    method: 'post',
    data
  })
}


//查询量体任务净体数据 
export function queryMeasureBareSize(data) {
  return service({
    url: '/measureTask/queryMeasureBareSize',
    method: 'post',
    data,
    headers: {
      'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8'
    }
  })
}

//回显
export function getBodyInfoCached(data) {
  return service({
    url: '/measureBodyMeasure/queryMeasurePersionData',
    method: 'post',
    data
  })
}

//查量体人 量体数据
export function getPersonData(params) {
  return service({
    url: '/tailor/api/person-data',
    method: 'get',
    params
  })
}

// 净体部位
export function getBareBody() {
  return service({
    url: '/measurePosition/queryAllMeasurePosition',
    method: 'post'
  })
}

// 添加/暂存量体数据
export function getMeasureDataSave(data) {
  return service({
    url: '/measureBodyMeasure/addOrUpdateBodyMeasure',
    method: 'post',
    data
  })
}

// 判号
export function getJudgeSize(data) {
  return service({
    url: '/measureBodyMeasure/judgeSize',
    method: 'post',
    data
  })
}

// 查询人物所属单位下的花名册列表
export function getUnitPersons(params) {
  return service({
    url: '/tailor/api/unit-persons',
    method: 'get',
    params
  })
}

// 查询售后人员
export function getAftersaleDataPerson(params) {
  return service({
    // url: '/admin/measure-tasks/aftersale-data-person',
    url: '/admin/measure-tasks/all-can-aftersale-persons',
    method: 'get',
    params
  })
}

//
export function getAftersalePerson(params) {
  return service({
    url: '/tailor/api/aftersale-person',
    method: 'get',
    params
  })
}
