export default {
  state: {
    // 量体任务详情，
    detailData: null
  },
  mutations: {
    setDetailData(state, result) {
      state.detailData = result
    }
  },
  actions: {

  }
}
