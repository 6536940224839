import {getUserAuth} from '@/utils/request';
export default{
    name:'auth',
    namespaced: true,
    state:{
        permission:{},
    },
    mutations:{
        setPermission(state,data){
            state.permission=data;
        },
        initPermisssion(state){
            state.permission={};
        },
    },
    actions:{
        async getPermission({commit,state}){
            if(state.permission&&Object.keys(state.permission).length){
                return state.permission;
            }else{
                return new Promise((resolve,reject)=>{
                    getUserAuth().then(res=>{
                        commit('setPermission',res.data||{});
                        resolve(res.data||{});
                    }).catch(err=>{
                        commit('setPermission',{});
                        reject({});
                    });
                });
            }
        }
    },
    getters:{

    }
}