const INDEX_FILTER_CACHE_NAME= 'indexFilterCache';
const ROUSTER_FILTER_CACHE_NAME= 'rousterFilterCache';
const OFFLINE_STATUS_CACHE_NAME= 'offlineStatus';
const APP_TOKEN_CACHE_NAME= 'appToken';
const USER_INFO_CACHE_NAME= 'userInfo';
const TASK_STATUS_CACHE_NAME= 'status';
const TASK_INDEX_CACHE_NAME= 'taskIndex';
const TASK_DETAIL_CACHE_NAME= 'taskDetail';
const TASK_PERSON_CACHE_NAME= 'personData';

//首页筛选条件
export function getIndexFilterCache(){
  let cacheStr=window.localStorage.getItem(INDEX_FILTER_CACHE_NAME);
  if(!cacheStr){
    return null;
  }else{
    return JSON.parse(cacheStr);
  }
}
//设置首页筛选条件
export function setIndexFilterCache(filterObj){
  if(typeof filterObj==='object'){
    window.localStorage.setItem(INDEX_FILTER_CACHE_NAME,JSON.stringify(filterObj));
  }
}

//花名册筛选条件
export function getRousterFilterCache(){
  let cacheStr=window.localStorage.getItem(ROUSTER_FILTER_CACHE_NAME);
  if(!cacheStr){
    return null;
  }else{
    return JSON.parse(cacheStr);
  }
}
//清空花名册筛选项条件缓存
export function clearRousterFilterCache(){
  window.localStorage.setItem(ROUSTER_FILTER_CACHE_NAME,'');
}

//设置花名册筛选条件
export function setRousterFilterCache(filterObj){
  if(typeof filterObj==='object'){
    window.localStorage.setItem(ROUSTER_FILTER_CACHE_NAME,JSON.stringify(filterObj));
  }
}

//用户自定义离线状态
export function getOfflineStatus(){
  let setValue=window.localStorage.getItem(OFFLINE_STATUS_CACHE_NAME);
  if(setValue){
    return JSON.parse(setValue);
  }else{
    return 0;
  }
}
//用户设置离线状态
export function setOfflineStatus(value){
  if(window.CACHE_SW){
    window.CACHE_SW.active.postMessage({type:'setOfflineStatus',value});
  }
  window.localStorage.setItem(OFFLINE_STATUS_CACHE_NAME, JSON.stringify(value));
}

export function getAppToken() {
  return window.sessionStorage.getItem(APP_TOKEN_CACHE_NAME);
}
export function setAppToken(data) {
  return window.sessionStorage.setItem(APP_TOKEN_CACHE_NAME,data);
}

export function removeAppToken(){
  window.sessionStorage.removeItem(APP_TOKEN_CACHE_NAME);
}

// login 用户信息
export function getUserInfo() {
  return window.sessionStorage.getItem(USER_INFO_CACHE_NAME)
}
export function setUserInfo(data) {
  return window.sessionStorage.setItem(USER_INFO_CACHE_NAME,data)
}


// task 获取任务状态
export function getStatus() {
  return window.sessionStorage.getItem(TASK_STATUS_CACHE_NAME)
}
// 存入任务状态
export function setStatus(data) {
  return window.sessionStorage.setItem(TASK_STATUS_CACHE_NAME,data)
}

// task 获取任务索引
export function getTaskIndex() {
  return window.sessionStorage.getItem(TASK_INDEX_CACHE_NAME)
}
// 存入任务 索引
export function setTaskIndex(data) {
  return window.sessionStorage.setItem(TASK_INDEX_CACHE_NAME,data)
}

// task 获取任务信息
export function getTaskDetail() {
  return window.sessionStorage.getItem(TASK_DETAIL_CACHE_NAME)
}
// 存入任务信息
export function setTaskDetail(data) {
  return window.sessionStorage.setItem(TASK_DETAIL_CACHE_NAME,data)
}

// task 获取 量体人信息
export function getPersonInfo() {
  return window.sessionStorage.getItem(TASK_PERSON_CACHE_NAME)
}
// 存入 量体人信息
export function setPersonInfo(data) {
  return window.sessionStorage.setItem(TASK_PERSON_CACHE_NAME,data)
}
