import Vue from 'vue';
import App from './App.vue';
// import './registerServiceWorker';
import router from './router';
import store from './store/index';
import axios from './axios';
import VueClipboard from 'vue-clipboard2'
import { Message, Loading, Notification, MessageBox } from 'element-ui'
import ElementUI from 'element-ui'; // 2.1引入结构
// ElementUI.zIndex=2024;
import 'element-ui/lib/theme-chalk/index.css'; // 2.2引入样式
import '@/assets/css/common.css';
import VueAMap from 'vue-amap';
import '@vuemap/vue-amap/dist/style.css'

import '@/fonts/tailorFont/iconfont.css';

Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  //申请地址 https://lbs.amap.com/ 选择web端jsAPI
  key: '2497e5a7b51952a546c10ba6c1d0f07c',
  // 插件集合，用到什么插件就使用什么插件
  plugin: [
    'AMap.CircleEditor',
    'AMap.Geolocation', // 定位控件，用来获取和展示用户主机所在的经纬度位置
    'AMap.Geocoder',
    'AMap.Autocomplete',
    'AMap.PlaceSearch',
    'AMap.CitySearch'
  ],
  v: '1.4.15' // 高德sdk版本，最新的sdk已经出到了2.0
  //官网解释：JSAPI 2.0 提供的开发接口与 1.4 版本达到 99%的兼容度，但是为了保证插件的稳定性我们还是选择1.4.4。
})

Vue.config.productionTip = false;
Vue.use(VueClipboard)
Vue.use(ElementUI,{zIndex:2024});
Vue.use(Loading.directive)
Vue.prototype.$message = Message
Vue.prototype.$loading = Loading.service
Vue.prototype.$notify = Notification
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$axios = axios;

//service worker
import {register} from '@/serviceWorker/index.js';
register();
//indexedDB
import myIndexDB from '@/indexedDB/index.js';
Vue.use(myIndexDB);

// //vue-worker
// import VueWorker from 'vue-worker'; // Web worker插件
// Vue.use(VueWorker);

//清除indexedDB旧数据
import {executeClear} from '@/tasks/clearIndexedDbDataTask.js';
executeClear();



//全局混入
import setOfflineStatusMixin from '@/mixins/setOfflineStatusMixin.js';
Vue.mixin(setOfflineStatusMixin);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
